<template>
  <div>
    <h1>UPDATE DATA FROM URL</h1>
    <h3>URL : {{url}}</h3>
    <div>
      <form @submit.prevent="update">
        <input type="number" placeholder="id" v-model.trim.number="id" required />
        <br />
        <input type="text" placeholder="name" v-model.trim="formData.name" required />
        <br />
        <input type="password" placeholder="password" v-model.trim="formData.password" required />
        <br />
        <button type="submit">Save</button>
      </form>
    </div>
    <h5>{{eventMessage}}</h5>
    <div>
      <h2>Data :</h2>
      {{dataLists}}
    </div>
    <div>
      <h2>Pagination :</h2>
      {{pagination}}
    </div>
  </div>
</template>

<script>
/* disable eslint */
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      id: "",
      url: "",
      formData: {
        name: "",
        password: "",
      },
    };
  },
  watch: {
    id(value) {
      if (value) {
        this.url = `api/user/${value}`;
      } else {
        this.url = "";
      }
    },
  },
  computed: {
    ...mapGetters(["dataLists", "pagination", "eventMessage"]),
  },
  methods: {
    update() {
      if (this.url) {
        this.$store.commit("setApiUrl", this.url);
        this.$store.commit("updateData", this.formData);
      } else {
        this.$store.commit("setEventMessage", "Please enter user id.");
      }
    },
  },
};
</script>
